import React from 'react';
import { PageProps } from 'gatsby';
import type { Locale } from 'features/localization';
import { CustomerReviewsPage as Page } from 'features/homepage';

function CustomerReviewsPage({ pageContext: { locale } }: PageProps<unknown, { locale: Locale }>) {
  return <Page locale={locale} />;
}

export default CustomerReviewsPage;
